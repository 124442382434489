export function getImage(
	src,
	{ modifiers, baseURL } = {},
	{ options, nuxtContext, $img }
) {
	const {
		width,
		height,
		/* preset, format, */ fit,
		ratio /* ...providerModifiers */,
		quality,
	} = modifiers;

	const url = new URL(src, 'https://example.com');

	// process modifiers

	if (width) {
		url.searchParams.set('width', width);
	}
	if (height) {
		url.searchParams.set('height', height);
	}
	if (fit) {
		url.searchParams.set('mode', fit);
	}
	if (ratio && width) {
		url.searchParams.set('height', width / parseFloat(ratio));
	}
	if (quality) {
		url.searchParams.set('quality', quality);
	}

	return {
		url: `${url.pathname}?${url.searchParams.toString()}`,
	};
}
