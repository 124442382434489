// Last modified: 2023/04/25 10:53:15
import breakpoints from '~/assets/js/breakpoints';

/**
 * Method to extract the page component used for the route if it exists.
 * @param {Route Object} route
 * @returns component object or undefined
 */
export function getAppFromRoute(route) {
	return route?.matched?.[0]?.instances?.default?.$nuxt ?? undefined;
}

/**
 * Method to compare to URL paths.
 * @param {string} a First path.
 * @param {string} b Second path.
 * @param {boolean} [ignoreQueryParams=true]
 * @returns {boolean}
 */
export function comparePaths(a, b, ignoreQueryParams = true) {
	if (a?.length && b?.length) {
		const splitRegex = /([^?]*)\??(.*)/;
		let [, aPath, aQuery] = a.match(splitRegex);
		let [, bPath, bQuery] = b.match(splitRegex);
		aPath = aPath.split('/').filter(Boolean);
		bPath = bPath.split('/').filter(Boolean);

		const aCompare = [aPath, !ignoreQueryParams && aQuery];
		const bCompare = [bPath, !ignoreQueryParams && bQuery];
		return aCompare.join('') === bCompare.join('');
	}

	return false;
}

/**
 * Helper to smartly fill in responsive sizes for images.
 * @param {string} sizes The sizes to fill in between.
 */
export function nuxtImgSizes(sizes) {
	let breakpointList = Object.entries(breakpoints)
		.map(([key, value]) => parseInt(value?.px))
		.filter((val) => Boolean(val) || val === 0);
	if (sizes) {
		const splitSizes = sizes.split(' ').filter((val) => {
			return Boolean(val) && val.split(':').filter(Boolean).length === 2;
		});
		splitSizes.sort((a, b) => {
			a = parseInt(a.split(':')[0].replace(/\D/g, ''), 10);
			b = parseInt(b.split(':')[0].replace(/\D/g, ''), 10);

			return a - b;
		});

		if (sizes.includes('vw') && breakpointList.length) {
			const output = [];

			for (let i = 0; i < splitSizes.length; i++) {
				const size = splitSizes[i].split(':')[1];
				const breakpoint = parseInt(
					splitSizes[i].split(':')[0].replace(/\D/g, ''),
					10
				);

				if (splitSizes[i].includes('vw')) {
					const midList = breakpointList
						.filter((val) => val < breakpoint)
						.map((val) => {
							return `<${val}:${size}`;
						});
					midList.length && output.push(...midList);
				}

				breakpointList = breakpointList.filter(
					(val) => val > breakpoint
				);
				output.push(splitSizes[i]);
			}
			return output.join(' ');
		}
		return splitSizes.join(' ');
	}
	return sizes;
}

/**
 * Scroll to element.
 * @param {HTMLElement} element The element to scroll to.
 * @param {object|boolean} scrollIntoViewOptions Options object or boolean to scroll to top.
 */
export function scrollToElement(element, scrollIntoViewOptions = true) {
	scrollToElement._runId = scrollToElement._runId || 0;
	const runId = ++scrollToElement._runId;

	// Compose options
	const options = {
		behavior: 'auto',
		block: 'start',
		inline: 'nearest',
		duration: 1000,
		allowDurationShortening: true,
		easeFunction: easeInOutCubic,
	};
	if (typeof scrollIntoViewOptions === 'boolean') {
		scrollIntoViewOptions = scrollIntoViewOptions
			? { block: 'start', inline: 'nearest' }
			: { block: 'end', inline: 'nearest' };
	}
	Object.assign(options, scrollIntoViewOptions);

	if (options.behavior === 'auto') {
		options.behavior = getComputedStyle(
			document.scrollingElement
		).scrollBehavior;
		if (options.behavior === 'auto') {
			options.behavior = 'instant';
		}
	}

	// Scroll to element
	const startTime = performance.now();
	const { scrollX: startX, scrollY: startY } = window;
	let endX = startX;
	let endY = startY;
	if (element) {
		// Just do standard scrollIntoView if we're not using animation
		if (options.behavior === 'instant') {
			element.scrollIntoView(options);
			return;
		}
		window.requestAnimationFrame(incrementScroll);
	}

	// Helper functions
	function incrementScroll(currentTime) {
		currentTime = performance.now(); // More precise

		// Stop if another scroll has been initiated
		if (runId !== scrollToElement._runId) {
			return;
		}

		if (element) {
			const { scrollX, scrollY } = window;
			element.scrollIntoView({ ...options, behavior: 'instant' });

			endX = window.scrollX;
			endY = window.scrollY;
			window.scrollTo(scrollX, scrollY);
		}

		// Scroll
		const durationX = Math.max(
			Math.min(options.duration, Math.abs(endX - startX)),
			100
		);
		const durationY = Math.max(
			Math.min(options.duration, Math.abs(endY - startY)),
			100
		);
		const duration = Math.max(durationX, durationY);
		window.scrollTo(
			lerp(
				startX,
				endX,
				options.easeFunction((currentTime - startTime) / duration),
				true
			),
			lerp(
				startY,
				endY,
				options.easeFunction((currentTime - startTime) / duration),
				true
			)
		);

		// Continue if there's still time left
		if (currentTime - startTime < duration) {
			window.requestAnimationFrame(incrementScroll);
		} else {
			window.scrollTo(endX, endY);
		}
	}

	function easeInOutCubic(x) {
		return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
	}
}

/**
 * Linear interpolation.
 * @param {number} valueStart The first value.
 * @param {number} valueEnd The second value.
 * @param {number} amount The interpolation value.
 * @param {boolean} clampValue Whether to clamp the value.
 */
export function lerp(valueStart, valueEnd, amount, clampValue = false) {
	if (clampValue) {
		amount = amount < 0 ? 0 : amount > 1 ? 1 : amount;
	}
	return (1 - amount) * valueStart + amount * valueEnd;
}

// And here we export all the helpers as well
export default {
	getAppFromRoute,
	comparePaths,
	nuxtImgSizes,
	scrollToElement,
	lerp,
};
