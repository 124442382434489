import Vue from 'vue';

/*
	Note that global components will be available across
	ALL solutions so only add global components with the
	greatest care.
*/
const components = {
	// component: () => import('~/components/shared/path'),
	NuxtPictureExt: () => import('~/components/shared/NuxtPictureExt.vue'),
	NuxtLinkExt: () => import('~/components/shared/NuxtLinkExt.vue'),
	TransitionExt: () => import('~/components/shared/TransitionExt.vue'),

	BaseButton: () => import('~/components/main/Base/BaseButton.vue'),
	BaseH1: () => import('~/components/main/Base/BaseH1.vue'),
	BaseH2: () => import('~/components/main/Base/BaseH2.vue'),
	BaseH3: () => import('~/components/main/Base/BaseH3.vue'),
	BaseH4: () => import('~/components/main/Base/BaseH4.vue'),
	BaseImg: () => import('~/components/main/Base/BaseImg.vue'),
	BasePlaceholder: () => import('~/components/main/Base/BasePlaceholder.vue'),
};

Object.entries(components).forEach(([name, component]) => {
	Vue.component(name, component);
});
