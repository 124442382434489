const getMatchedComponents = (route, matches = false, prop = 'components') => {
	return Array.prototype.concat.apply(
		[],
		route.matched.map((m, index) => {
			return Object.keys(m[prop]).map((key) => {
				matches && matches.push(index);
				return m[prop][key];
			});
		})
	);
};

const setScrollRestoration = (newVal) => {
	try {
		window.history.scrollRestoration = newVal;
	} catch (e) {}
};

if (process.client) {
	if ('scrollRestoration' in window.history) {
		setScrollRestoration('manual');

		// reset scrollRestoration to auto when leaving page, allowing page reload
		// and back-navigation from other pages to use the browser to restore the
		// scrolling position.
		window.addEventListener('beforeunload', () => {
			setScrollRestoration('auto');
		});

		// Setting scrollRestoration to manual again when returning to this page.
		window.addEventListener('load', () => {
			setScrollRestoration('manual');
		});
	}
}

function shouldScrollToTop(route) {
	const Pages = getMatchedComponents(route);
	if (Pages.length === 1) {
		const { options = {} } = Pages[0];
		return options.scrollToTop === true;
	}
	return Pages.some(({ options }) => options && options.scrollToTop === true);
}

const savedPositionMap = new Map();
export default (to, from, savedPosition) => {
	// If the returned position is falsy or an empty object, will retain current scroll position
	let position = false;
	const isPopState = !!savedPosition;
	const isRouteChanged = to !== from;
	const isPathSame = to.path === from.path;
	const isHashChanged = to.hash !== from.hash;

	savedPositionMap.set(from.fullPath, {
		x: window.scrollX || document.body.scrollLeft,
		y: window.scrollY || document.body.scrollTop,
	});
	if (isPopState) {
		savedPosition = savedPositionMap.get(to.fullPath);
	}

	// savedPosition is only available for popstate navigations (back button)
	if (shouldScrollToTop(to)) {
		position = { x: 0, y: 0, blurFocus: true };
	} else if (savedPosition) {
		position = savedPosition;
	} else if (isRouteChanged) {
		position = { x: 0, y: 0, blurFocus: true };
	}

	const nuxt = window.$nuxt;

	if (!isRouteChanged || (isPathSame && isHashChanged)) {
		nuxt.$nextTick(() => nuxt.$emit('triggerScroll'));
	} else if (isRouteChanged && !isPathSame && position.blurFocus) {
		// Blur the focus
		nuxt.$nextTick(() => {
			const hasTabindex = document.body.hasAttribute('tabindex');
			const oldTabIndex = document.body.getAttribute('tabindex');
			document.body.setAttribute('tabindex', '-1');

			document.body.focus();
			document.activeElement.blur();

			if (hasTabindex) {
				document.body.setAttribute('tabindex', oldTabIndex);
			} else {
				document.body.removeAttribute('tabindex');
			}
		});
	}

	return new Promise((resolve) => {
		// wait for the out transition to complete (if necessary)
		nuxt.$once('triggerScroll', () => {
			// coords will be used if no selector is provided,
			// or if the selector didn't match any element.
			if (to.hash) {
				let hash = to.hash;
				// CSS.escape() is not supported with IE and Edge.
				if (
					typeof window.CSS !== 'undefined' &&
					typeof window.CSS.escape !== 'undefined'
				) {
					hash = '#' + window.CSS.escape(hash.substr(1));
				}
				try {
					const el = document.querySelector(hash);
					if (el) {
						// scroll to anchor by returning the selector
						position = {
							selector: hash,
							behavior: window.matchMedia(
								'(prefers-reduced-motion: reduce)'
							).matches
								? 'auto'
								: 'smooth',
						};
						// Respect any scroll-margin-top set in CSS when scrolling to anchor
						const y = Number(
							getComputedStyle(el)['scroll-margin-top']?.replace(
								'px',
								''
							)
						);
						if (y) {
							position.offset = { y };
						}
					}
				} catch (e) {
					console.warn(
						'Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).'
					);
				}
			}

			resolve(position);
		});
	});
};
