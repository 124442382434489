// Last modified: 2023/05/01 09:13:09
import Vue from 'vue';
const { visualViewport } = typeof window === 'undefined' ? {} : window;

const viewportData = Vue.observable({
	width: 0,
	height: 0,
	offsetTop: 0,
	offsetLeft: 0,
	pinchZoom: 1,
});

if (visualViewport) {
	let pendingUpdate = false;
	const viewportHandler = () => {
		if (pendingUpdate) {
			return;
		}
		pendingUpdate = true;

		requestAnimationFrame(() => {
			pendingUpdate = false;

			const { width, height, offsetTop, offsetLeft } = visualViewport;
			const { documentElement } = document;
			const { clientWidth, clientHeight } = documentElement;

			viewportData.width = clientWidth;
			viewportData.height = clientHeight;
			viewportData.offsetTop = (offsetTop / width) * clientWidth;
			viewportData.offsetLeft = (offsetLeft / height) * clientHeight;
			viewportData.pinchZoom = (1 / width) * clientWidth;

			if (CSS.supports('width', '100dvw') && true) {
				documentElement.style.setProperty(
					'--visual-viewport-width',
					'100dvw'
				);
				documentElement.style.setProperty(
					'--visual-viewport-height',
					'100dvh'
				);
			} else {
				documentElement.style.setProperty(
					'--visual-viewport-width',
					`${width}px`
				);
				documentElement.style.setProperty(
					'--visual-viewport-height',
					`${height}px`
				);
			}
			documentElement.style.setProperty(
				'--visual-viewport-offset-top',
				`${offsetTop}px`
			);
			documentElement.style.setProperty(
				'--visual-viewport-offset-left',
				`${offsetLeft}px`
			);
		});
	};

	viewportHandler();
	visualViewport.addEventListener('scroll', viewportHandler);
	visualViewport.addEventListener('resize', viewportHandler);
}

export { viewportData };
