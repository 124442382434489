<template>
	<Component
		:is="doctype"
		v-if="doctype"
		v-bind="{ ...$data, isFirstPage }"
	/>
</template>

<script>
import fetchUmbracoData from '@limbo-works/umbraco-client/client';
import { mapGetters } from 'vuex';

import { cookieInformationData as cookieInformation } from '../components/shared/CookieInformation.vue';

import doctypes from '~/assets/js/doctypes';
import key from '~/assets/js/page-key';
import transition from '~/assets/js/page-transition.js';

let isFirstPage = true;

export default {
	name: 'WildcardPage',

	key: (route) => key(route),

	components: {
		...doctypes,
	},

	beforeRouteUpdate(to, from, next) {
		if (from?.path !== to?.path) {
			to.params.from = from.fullPath;
		}

		if (
			from.path.substring(1, 3) &&
			from.path.substring(1, 3) !== to.path.substring(1, 3)
		) {
			location.href = to.fullPath;
			return;
		}

		if (this.$matomo) {
			this.$matomo.setCustomVariable(
				1,
				'PageTitle',
				this.$store.state.currentPage.title,
				'page'
			);
			this.$matomo.setCustomVariable(
				2,
				'PageId',
				this.$store.state.currentPage.id,
				'page'
			);
		}

		next();
	},

	layout: ({
		store: {
			getters: { layout },
		},
	}) => layout,
	transition,

	async asyncData({
		req,
		$config,
		error,
		redirect,
		route,
		store: { commit },
	}) {
		// Get data
		let fallbackHost = '';
		if (process.server) {
			fallbackHost = req.headers.host;
		} else {
			const { host } = new URL(window.location.href);
			fallbackHost = host;
		}

		let displayErrorPage = false;
		const data = await fetchUmbracoData({
			endpointUrl: process.env.REDIS_URL
				? process.env.GET_DATA_ENDPOINT
				: undefined,
			onResponse(response) {
				process.env.NODE_ENV === 'development' &&
					console.log('Page requesting', response?.config?.url);

				commit('Cookies/SET_COOKIE', response?.headers?.['set-cookie']);
				commit(
					'SET_LANGUAGE_PICKER_VISIBILITY',
					!['employee', 'employeeEn'].includes(
						response?.data?.content?.template
					)
				);
				return response;
			},
			error: (err) => {
				displayErrorPage = true;
				return error(err);
			},
			params: {
				...route.query,
				...(process.env.NODE_ENV === 'development'
					? { cache: false }
					: {}),
				parts: ['content'],
				appHost: $config.APP_HOST || fallbackHost,
				headers: {
					cookie: req?.headers?.cookie || '',
				},
			},
			redirect: (...args) => {
				redirect(...args);
			},
			route,
		});

		process.env.NODE_ENV === 'development' &&
			console.log('Is from cache?', !!data?.isFromCache);

		// NOTE: if data is falsy, a redirect has taken place, or an error has been handled
		if (!data) {
			return;
		}

		const { content = {} } = data;
		commit('SET_TEMPLATE', content.template);
		commit('SET_CURRENT_PAGE', content);

		if (displayErrorPage) {
			commit('SET_ERROR_DATA', content);

			return;
		}

		return content;
	},

	data() {
		return {
			doctype: this.$store.getters.doctype,
			isFirstPage,
		};
	},

	head() {
		const meta = Object.assign({}, this.meta || {});
		meta.meta = meta.meta || [];
		meta.script = meta.script || [];

		const gtmId = 'GTM-N5739D9';
		meta.script.push(
			gtmId && {
				hid: 'gtm',
				skip:
					!this.cookieInformation?.categories?.statistic ||
					!this.cookieInformation?.userHasInteracted,
				src: `https://www.googletagmanager.com/gtag/js?id=${gtmId}`,
			}
		);

		!meta.meta.find((item) => item?.name === 'format-detection') &&
			meta.meta.push({
				// iOS phone number formatting can lead to hydration errors resulting in a double load
				hid: 'format-detection',
				name: 'format-detection',
				content: 'telephone=no',
			});

		return meta;
	},

	computed: {
		...mapGetters(['solution']),

		cookieInformation() {
			return cookieInformation;
		},
	},

	created() {
		// Check if logged in
		process.client &&
			this.$axios.get('/api/login/isloggedin').then((response) => {
				this.$store.commit('SET_IS_LOGGED_IN', !!response.data);
			});

		// We only change the theme in the store when the page is created, the asyncData is too early
		this.$store.commit('SET_THEME', this.colorScheme);
		isFirstPage = false;
	},
};
</script>
