// Last modified: 2023/01/26 16:04:11
import { getAppFromRoute } from '~/assets/js/utilities/helpers';

export default (to, from) => {
	const fromComponent = getAppFromRoute(from);
	const toComponent = getAppFromRoute(to);
	const solution = (fromComponent || toComponent)?.$store?.getters?.solution;
	if (solution) {
		if (solution === 'main') {
			return {
				name: 't-page-main',
				mode: 'out-in',
			};
		}
		return `t-page-${solution}`;
	}

	return 't-page';
};
