import { camelCase, upperFirst } from 'lodash';

const DOCTYPES_BY_SOLUTION_AND_TEMPLATE = {
	// Plop: doctype - add solution
	main: {
		// Plop: doctype - add main doctype
		employee: () =>
			import(
				/* webpackChunkName: "main__doctypeEmployeePage" */ '~/doctypes/main/EmployeePage.vue'
			),
		employeeEn: () =>
			import(
				/* webpackChunkName: "main__doctypeEmployeeEnPage" */ '~/doctypes/main/EmployeeEnPage.vue'
			),
		loginPage: () =>
			import(
				/* webpackChunkName: "main__doctypeLoginPage" */ '~/doctypes/main/LoginPage.vue'
			),
		searchPage: () =>
			import(
				/* webpackChunkName: "main__doctypeSearchPage" */ '~/doctypes/main/SearchPage.vue'
			),
		expertList: () =>
			import(
				/* webpackChunkName: "main__doctypeEmployeeList" */ '~/doctypes/main/EmployeeList.vue'
			),
		employeeList: () =>
			import(
				/* webpackChunkName: "main__doctypeEmployeeList" */ '~/doctypes/main/EmployeeList.vue'
			),
		projectList: () =>
			import(
				/* webpackChunkName: "main__doctypeProjectList" */ '~/doctypes/main/ProjectList.vue'
			),
		publicationList: () =>
			import(
				/* webpackChunkName: "main__doctypePublicationList" */ '~/doctypes/main/PublicationList.vue'
			),
		frontPage: () =>
			import(
				/* webpackChunkName: "main__doctypeFrontpage" */ '~/doctypes/main/Frontpage.vue'
			),
		eventPage: () =>
			import(
				/* webpackChunkName: "main__doctypeEventPage" */ '~/doctypes/main/EventPage.vue'
			),
		eventList: () =>
			import(
				/* webpackChunkName: "main__doctypeEventList" */ '~/doctypes/main/EventList.vue'
			),
		newsList: () =>
			import(
				/* webpackChunkName: "main__doctypeNewsList" */ '~/doctypes/main/NewsList.vue'
			),
		area: () =>
			import(
				/* webpackChunkName: "main__doctypeContentPage" */ '~/doctypes/main/ContentPage.vue'
			),
		contentPage: () =>
			import(
				/* webpackChunkName: "main__doctypeContentPage" */ '~/doctypes/main/ContentPage.vue'
			),
		newsPage: () =>
			import(
				/* webpackChunkName: "main__doctypeContentPage" */ '~/doctypes/main/ContentPage.vue'
			),
		project: () =>
			import(
				/* webpackChunkName: "main__doctypeContentPage" */ '~/doctypes/main/ContentPage.vue'
			),
		publication: () =>
			import(
				/* webpackChunkName: "main__doctypeContentPage" */ '~/doctypes/main/ContentPage.vue'
			),
		themePage: () =>
			import(
				/* webpackChunkName: "main__doctypeContentPage" */ '~/doctypes/main/ContentPage.vue'
			),
		overviewList: () =>
			import(
				/* webpackChunkName: "main__doctypeOverviewList" */ '~/doctypes/main/OverviewList.vue'
			),
		areaList: () =>
			import(
				/* webpackChunkName: "main__doctypeOverviewList" */ '~/doctypes/main/OverviewList.vue'
			),
		themeOverviewList: () =>
			import(
				/* webpackChunkName: "main__doctypeOverviewList" */ '~/doctypes/main/OverviewList.vue'
			),
		frontPageUk: () =>
			import(
				/* webpackChunkName: "main__doctypeFrontpageUk" */ '~/doctypes/main/FrontpageUk.vue'
			),
	},
	shared: {
		// Plop: doctype - add shared doctype
	},
};

export const getDoctypeFromSolutionAndTemplate = (solution, template) =>
	upperFirst(camelCase(`${solution}${upperFirst(template)}`));

// format that allows local registration via `components`
export default Object.entries(DOCTYPES_BY_SOLUTION_AND_TEMPLATE).reduce(
	(acc, [solution, doctypesByTemplate]) => ({
		...acc,

		...Object.entries(doctypesByTemplate).reduce(
			(acc, [template, doctype]) => ({
				...acc,

				[getDoctypeFromSolutionAndTemplate(solution, template)]:
					doctype,
			}),
			{}
		),
	}),
	{}
);
