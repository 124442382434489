

export default () => {
    if (process.server) {
        return;
    }

    const detect = () => 'requestIdleCallback' in window;

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "requestidlecallback-polyfill"
        ], function (require) {
            const polyfill = require("requestidlecallback-polyfill");

            resolve();
        });
    });
}